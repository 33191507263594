<template>
  <div class="container-fluid">
    <b-skeleton-wrapper :loading="offersLayoutLoading">
      <template #loading>
        <b-container fluid>
          <landingLoader></landingLoader>
        </b-container>
      </template>
      <div class="row">
        <b-col sm="3" v-if="!offersLayoutLoading && items != null">
          <SidePage
            @Change-Search="ChangeSearch"
            :max="maxProdPrice"
          ></SidePage>
        </b-col>
        <b-col sm="9">
          <b-container>
            <b-row>
              <div class="ProdList container my-3">
                <b-row v-if="!offersLayoutLoading && OffersLayout.tag != null">
                  <b-col
                    sm="3"
                    cols="6"
                    class="px-1 my-1"
                    v-for="Product in itemsForList"
                    v-bind:key="Product.id"
                  >
                    <ProdCard
                      class="ProdByCatItem"
                      v-bind:Product="Product"
                    ></ProdCard>
                  </b-col>
                </b-row>
                <b-row>
                  <b-pagination
                    v-if="!offersLayoutLoading && items.length > 0"
                    class="my-3"
                    v-model="currentPage"
                    :total-rows="items.length"
                    :per-page="perPage"
                    aria-controls="my-cards"
                  ></b-pagination>
                </b-row>
              </div>
            </b-row>
          </b-container>
        </b-col>
      </div>
      <b-row v-if="OffersLayout.tag == null" align-h="center">
        <div class="bg-white p-5 mb-4">
          <h3 class="text-primary text-center">
            لا يوجد عروض خاصة على المنتجات في الوقت الحالي
          </h3>
          <b-row align-h="center">
            <b-button
              variant="outline-secondary"
              class="py-1 mt-3 font-weight-bold"
              :style="[$mq == 'sm' ? 'font-size: 12px;' : 'font-size: 15px;']"
              :to="{ name: 'Home' }"
              >{{ $t("Main") }}</b-button
            >
          </b-row>
        </div>
      </b-row>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SidePage from "../components/widgets/SidePage.vue";
import ProdCard from "../components/items/ProdCard.vue";
import landingLoader from "../components/widgets/landingLoader.vue";

export default {
  components: {
    SidePage,
    ProdCard,
    landingLoader,
  },
        metaInfo: {
    title: "Offers",
  },
  computed: {
    ...mapGetters(["OffersLayout"]),
    itemsForList() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return this.items.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
  },
  data() {
    return {
      items: [],
      perPage: 12,
      currentPage: 1,
      offersLayoutLoading: true,
      searchform: {
        minprice: 0.1,
        maxprice: 2000,
        highRate: 5,
        lowRate: 0,
        limit: 20,
        sort: "DESC",
        condition: "New",
      },
      maxProdPrice: 0,
    };
  },
  methods: {
    ...mapActions(["getProdByTag"]),
    getResults(page = 1) {
      var obj = {
        tag: "1138",
        breakpoint: this.$mq,
        Where: "offers",
        page: page,
        filter: this.searchform,
      };
      this.getProdByTag(obj);
    },
    ChangeSearch(sform) {
      this.searchform = sform;
      this.getResults();
    },
  },
  mounted() {
       this.$gtag.event('Offers Page', { method: 'Google' })

    this.getResults();
  },
  watch: {
    OffersLayout(newValue) {
      if (newValue) {
        this.items = newValue.tag;
        this.offersLayoutLoading = false;
        this.maxProdPrice = newValue.maxPrice;
 
      }
    },
  },
};
</script>

<style>
</style>